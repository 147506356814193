input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.phone > input {
  margin-top: 1em;
  height: 3.5em;
  border-radius: 10px;
  padding: 0 12px;
  font-size: medium;
}

.project-card {
  border: 2px solid transparent;
  transition: 0.3s;
}

.project-card:hover {
  border: 2px solid #2065d1;
  color: #2065d1;
}

.list-btn {
  padding: 12px 6px;
  border-radius: 8px;
  border: 3px solid transparent;
  background: #2064d10e;
}

.list-btn:hover {
  border: 3px solid #2065d1;
}

.custom-header {
  background: linear-gradient(160deg, #ffab00, #86e8ab, #2065d1) 0 0 / 400% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (prefers-reduced-motion: no-preference) {
  .custom-header {
    animation: move-bg 20s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: 400% 0;
    }
  }
}

.custom-bg {
  height: 100%;
  width: 100%;
  background: linear-gradient(210deg, #a960ee 0%, #86e8ab 25%, #90e0ff 50%, #ffcb57 100%);
  clip-path: polygon(0 0, 100% 0, 100% 28%, 0% 70%);
  background-size: 400% 400%;
  position: absolute;
  animation: gradient-animation 10s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media only screen and (max-width: 600px) {
  .custom-bg {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
